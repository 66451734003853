.category-card {
    display: flex;
    width: 100%;
    height: 196px;
    background-color: $white;
    border: 1px solid #E9E9E9;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-down(lg) {
        height: 230px;
    }
    &:hover {
        border-color: $primary-color;
        box-shadow: 0 0 30px 0 rgba(0,0,0,.15);
    }
    &:hover .category-card__content span:after {
        right: -50px;
    }
    &__content {
        img {
            max-width: 116px;
            @include media-breakpoint-between(lg,xxl) {
                max-width: 80px;
            }
        }

        h3 {
            @include fluid-font($min-vw,$max-vw,20px,30px);
            color: $text-color;
        }

        span {
            padding-bottom: 3px;
            border-bottom: 1px solid rgba($primary-color,.14);
            text-transform: uppercase;
            color: $primary-color;
            letter-spacing: 2px;
            position: relative;
            font-weight: 600;
            font-size: rem(12px);
            &:after {
                content: " ";
                width: 28px;
                height: 29px;
                background: url('/img/right-arrow-grey.svg');
                transition: all .3s ease-in-out;
                position: absolute;
                right: -40px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}

.product-card {
    display: block;
    overflow: hidden;
    &:hover {
        box-shadow: 0 0 30px 0 rgba(0,0,0,.15);
        .product-card__content {
            transform: translateX(20px);
        }
    }
    &__img {
        aspect-ratio: 1/0.9;
        position: relative;
        border: 1px solid #F4F4F4;
        background-color: rgba(0,0,0,.02);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        img, picture {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &:hover .product-card__overlay-img {
            opacity: 1;
        }

        .label {
            position: absolute;
            top: 10px;
            left: 10px;
            font-size: rem(12px);
            text-transform: uppercase;
            color: $white;
            letter-spacing: 1px;
            font-weight: 600;
            padding: 0 10px;
            display: block;
            border-radius: 30px;
            &.orange {
                background-color: #FFA800;
            }
        }
    }

    &__overlay-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: opacity .3s ease-in-out;
        opacity: 0;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__content {
        padding-bottom: 20px;
        display: block;
        transition: transform .2s ease-in-out;
        h4 {
            font-size: rem(18px);
            font-weight: 600;
            color: $text-color;
        }

        h5 {
            font-size: rem(16px);
            color: #BDBDBD;
            font-weight: 500;
        }

        .price {
            font-weight: 500;
            color: $red;
            font-size: rem(14px);


        }

        .old-price {
            font-size: rem(12px);
            color:#BDBDBD;
            text-decoration: line-through;
            padding-left: 5px;
        }

        .price-info {
            font-size: rem(12px);
            color:#BDBDBD;
            padding-left: 5px;
        }
    }
}
