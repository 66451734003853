.team-detail {
    padding: 108px 0;
    .content {
        padding-top: 30px;
        h1 {
            @include fluid-font($min-vw,$max-width,32px,78px);
            font-weight: 500;
            line-height: 1;
        }
        h2 {
            @include fluid-font($min-vw,$max-width,20px,36px);
            font-weight: 500;
        }

        cite {
            font-style: normal;
            color: $primary-color;
            font-weight: 500;
            font-size: rem(22px);
            margin-top: 50px;
            display: block;
        }

        p {
            margin-top: 22px;
            margin-bottom: 0;
            line-height: 1.43;
            strong {
                color: $primary-color;
            }
        }
    }

    .grid-container {
        display: grid;
        max-width: 25rem;
        grid-auto-rows: 17rem;
        width: 100%;
        height: fit-content;
        //grid-template-columns: auto auto;

        .grid-item {
            background-color: transparent;
            text-align: center;
            padding: 3px;
            width: 100%;
            height: 100%;
            overflow: hidden;

            .image {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        &.grid-container-1 {
            //grid-template-columns: auto auto;
            grid-auto-rows: 34rem;
        }

        &.grid-container-2 {
            //grid-template-columns: auto auto;
        }

        &.grid-container-3 {
            .item1 {
                grid-column: 1 / span 2;
                grid-row: 1;
            }
        }
    }

    .image {
        max-width: 25rem;
        width: auto;
        max-height: 34rem;
    }
}
