.toastify {
    padding: 1.2rem 1.75rem;
    color: #ffffff;
    display: inline-block;
    position: fixed;
    font-size: 90%;
    opacity: 0;
    transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
    border-radius: 0.75rem;
    cursor: pointer;
    text-decoration: none;
    max-width: calc(50% - 20px);
    z-index: 2147483647;
    box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);

    &.success {
        background: -webkit-linear-gradient(315deg, #009a5a, #00673c);
        background: linear-gradient(135deg, #009a5a, #00673c);
    }

    &.error {
        background: -webkit-linear-gradient(315deg, #800020, #4d0013);
        background: linear-gradient(135deg, #800020, #4d0013);
    }
    &.warning {
        background: -webkit-linear-gradient(315deg, lighten(#ffc107, 10%), #ffc107);
        background: linear-gradient(135deg, lighten(#ffc107, 10%), #ffc107);
    }
}

.toastify.on {
    opacity: 1;
}

.toast-close {
    position: absolute;
    top: 7.5px;
    right: 10px;
    opacity: 0.4;
}

.toastify-right {
    right: 15px;
}

.toastify-left {
    left: 15px;
}

.toastify-top {
    top: -150px;
}

.toastify-bottom {
    bottom: -150px;
}

.toastify-rounded {
    border-radius: 25px;
}

.toastify-avatar {
    width: 1.5em;
    height: 1.5em;
    margin: -7px 5px;
    border-radius: 2px;
}

.toastify-center {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    max-width: fit-content;
    max-width: -moz-fit-content;
}

@media only screen and (max-width: 360px) {
    .toastify-right, .toastify-left {
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        max-width: fit-content;
    }
}
