#hero-slider {
    .swiper-pagination {
        position: absolute;
        max-width: 600px;
        margin: 0 auto;
        display: flex;
        bottom: 30px;
        left: 0;
        right: 0;
        z-index: 2;
        gap: 20px;
        padding: 0 30px;
        span {
            display: block;
            width: 100%;
            height: 2px;
            background: rgba(178,166,156,.2);
            cursor: pointer;
            position: relative;
            &:before {
                content: " ";
                width: 0;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: rgba(178,166,156,1);
                transition: width .3s ease-in-out;
            }
            &.swiper-pagination-bullet-active:before {
                width: 100%;
            }
        }
    }
}


.bottom-slider {
    .swiper-pagination-bullet {
        display: block;
        height: 100%;
        flex-grow: 1;
        position: relative;
        &:after {
            content: " ";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: width 1s ease-in-out;
            background-color: $nav-color;
        }
    }

    .swiper-pagination-bullet-active ~ span:after {
        width: 0;
    }
}
