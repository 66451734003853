html,body {
    overflow-x: hidden;
}

.scroll-lock {
    overflow: hidden;
}

body {
    margin: 0;
    background-color: $white;
    font-family: $text-font !important;
}


* {
    margin: 0;
    padding: 0;
}

ul {
    margin: 0 !important;
}

button {
    border: none;
    background: none;
}

a {
    text-decoration: none !important;
    color: white;
}

a,button {
    transition: all .2s ease-in-out;
}

a:hover{
    color: #ED3C3C;

}

.w-full{
    width: 100%;
}

.text-center {
    text-align: center;
}

main {
    @include media-breakpoint-up(lg) {
        margin-top: 114px;
    }

    @include media-breakpoint-down(lg) {
        margin-top: 65px;
    }
}


.white-bg-section {
    background-color: $white;
    padding: 40px 0;
}

section h3 {
    font-size: rem(30px);
    font-weight: 600;
}

section h2 {
    @include fluid-font($min-width,$max-width,28px,50px);
    color: $text-color;
}

section p {
    color: rgba(12,12,12,.5);
    line-height: 1.7;
}

.text-capitalize {
    text-transform: capitalize;
}
