/* CHECKBOX STYLE */
.checkbox {
    overflow: hidden;
    font-size: 0.875rem;
    .checkbox-input {
        display: none;
        &:checked + .checkbox-label {
            .check {
                background: $white;
                img, .check-img {
                    visibility: visible !important;
                }
            }
            .checkbox-span {
                background: $white;
            }
        }
    }
    .check {
        border: 2px solid $text-color;
        width: 24px;
        height: 24px;
        flex: none;
        img, .check-img {
            visibility: hidden !important;
        }
    }

    .checkbox-label-text {
        font-size: rem(16);
        line-height: normal;

        a {
            color: $primary-color;
            transition: all .3s;

            &:hover {
                color: $text-color;
            }
        }
    }

    .checkbox-label {
        cursor: pointer;

        .checkbox-span {
            transition: all 0.2s ease-in-out;
            border: 2px solid $text-color;
            min-width: 88px;
            min-height: 88px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.125rem;

            @media (max-width: 1200px) {
                min-width: 77px;
                min-height: 77px;
            }
            @media (max-width: 991px) {
                min-width: 55px;
                min-height: 55px;
            }
        }
    }
}
