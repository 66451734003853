.contact {
    padding: 108px 0;
    h1 {
        font-weight: 500;
        @include fluid-font($min-width,$max-width,32px,78px);
        color: #221C18;
        line-height: 1;
        margin-bottom: 30px;
    }
    h6 {
        font-size: rem(18px);
        color: rgba(12,12,12,.5);
        font-weight: 400;
        line-height: 1.65;
    }

    .contact-card {
        padding: 70px 78px;
        background-color: #F9FAFB;
        @include media-breakpoint-down(md) {
            padding: 30px;
            margin-top: 30px;
        }
        strong {
            display: block;
            a {
                color: $primary-color;
                font-weight: 600;
                position: relative;
                &:hover:after {
                    width: 100%;
                }
                &:after {
                    content: " ";
                    width: 0;
                    height: 1px;
                    background-color: $primary-color;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    transition: width .3s ease-in-out;
                }
            }
        }

        h5 {
            font-size: rem(20px);
        }
    }

    .contact-form-card {
        padding: 65px 100px;
        position: relative;
        background: linear-gradient(to bottom, rgba(229,227,221,1), rgba(229,227,221,0));
        @include media-breakpoint-down(xl) {
            padding: 40px;
        }

        @include media-breakpoint-down(sm) {
            padding: 40px 25px;
        }
        &:before {
            content: " ";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            background: linear-gradient(to right, rgba(240,228,207,1), rgba(255,255,255,0)50%);
        }
        &__wrap {
            position: relative;
            z-index: 2;
        }
        h3 {
            margin-bottom: 20px;
        }
        input {
            width: 100%;
            height: 58px;
            border: none;
            background-color: $white;
            margin-bottom: 14px;
            border-radius: 30px;
            padding: 0 20px;
            outline: none;
        }

        textarea {
            width: 100%;
            height: 114px;
            border-radius: 24px;
            border: none;
            resize: none;
            outline: none;
            padding: 20px;
            margin-bottom: 14px;
        }

        input[type="checkbox"] {
            display: none;
            &:checked + label span {
                border-color: transparent;
                background-color: $primary-color;
                &:before {
                    opacity: 1;
                }
            }
        }

        label[for="gdpr"] {
            cursor: pointer;
            color: #838384;
            position: relative;
            padding-left: 35px;
            a {
                color: $primary-color;
                text-decoration: underline !important;
            }
            span {
                display: inline-block;
                width: 22px;
                height: 22px;
                border: 1px solid #838384;
                position: absolute;
                left: 0;
                top: 0;
                border-radius: 8px;
                &:before {
                    content: " ";
                    width: 13px;
                    height: 13px;
                    background: url('/img/check.svg');
                    position: absolute;
                    top: 3px;
                    left: 3px;
                    opacity: 0;
                }
            }
        }

        button {
            height: 58px;
            padding: 0 45px;
            text-transform: uppercase;
            letter-spacing: 1.2px;
            color: $white;
            background-color: $text-color;
            border-radius: 30px;
            font-weight: 500;
            font-size: rem(14px);
            margin-top: 25px;
            &:hover {
                background-color: lighten($text-color,10%);
                img {
                    transform: translateX(10px) translateY(-2px);
                }
            }
            img {
                transition: transform .2s ease-in-out;
                padding-left: 3px;
                transform: translateY(-2px);
            }
        }
    }

    .map {
        height: 100%;
        max-height: 644px;
        border: 1px solid $black;
        box-shadow: 0 4px 4px 0 rgba($black,.25);
        @include media-breakpoint-down(lg) {
            height: 300px;
        }
        iframe {
            height: 100%;
        }
    }
}
