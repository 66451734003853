.pagination-custom {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0;

    .page-item {
        margin: 0 0.3rem;

        font-weight: bold;
        border: 1px solid $grey-bg;
        font-family: $text-font;
        position: relative;
        color: $text-color;
        text-decoration: none;
        background-color: $grey-bg;
        transition: all .2s;

        display: block;
        //justify-content: center;
        //align-items: center;
        width: 2.375rem;
        height: 2.625rem;

        .page-link {
            font-weight: bold;
            font-family: $text-font;

            height: 100%;
            width: 100%;

            display: flex;
            justify-content: center;
            align-items: center;
        }

        &:hover {
            z-index: 2;
            text-decoration: none;
            color: $white;
            background-color: $primary-color;
            cursor: pointer;
        }

        &:focus {
            z-index: 3;
            outline: 0;
            box-shadow: none;
        }

        &.disabled {
            pointer-events: none;
            cursor: auto;
            background-color: lighten($grey-bg, 30%);
        }

        &.active {
            background-color: $primary-color;
            color: $white;
        }
    }

//.page-item {
//    border: none;
//    margin: 0 0.3rem;
//
//    &.active .page-link {
//        z-index: 3;
//        color: $text-color;
//        background-color: rgba($grey-bg, .9);
//        height: 100%;
//        width: 100%;
//    }
//
//    &.disabled .page-link {
//        pointer-events: none;
//        cursor: auto;
//        color: $text-color;
//        background-color: lighten($grey-bg, 30%);
//        height: 100%;
//        width: 100%;
//    }
//}
}
